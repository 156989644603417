import moment from 'moment/moment'

export default {
  filters: {
    getShortDate(val) {
      if (val && isNaN(new Date(val))) return 'Помилка в даті'
      if (!val) return ''
      const d = new Date(val)
      const date = ('0' + d.getDate()).slice(-2)
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const year = d.getFullYear()
      return `${date}.${month}.${year}`
    },

    getShortTime(val) {
      if (val && isNaN(new Date(val))) return 'Помилка в часі'
      if (!val) return ''
      const d = new Date(val)
      // const time = d.getTime() + d.getTimezoneOffset() * 60 * 1000
      /*const time = d.getTime()
      const convertedTime = new Date(time).toLocaleTimeString()
      return `${convertedTime}`*/
      const hours = d.getHours().toString().padStart(2, '0')
      const minutes = d.getMinutes().toString().padStart(2, '0')
      const seconds = d.getSeconds().toString().padStart(2, '0')
      return `${hours}:${minutes}:${seconds}`
    },

    formatDate(val) {
      return new moment(val).format('DD.MM.YYYY HH:mm:ss')
    },
  },
}
